import React, { useEffect, useState } from 'react';
import useNewsletter from '../hooks/useNewsletter';

import './Overlay.sass';
import { Close } from '../images/svg-icons';


const storageKey = 'newsletterBanner';

function Overlay({ children }) {
  const [ hide, setHide ] = useState(true);

  const {
    handleSubmit,
    inputEl,
    responseMsg
  } = useNewsletter();

  useEffect(() => {
    if (!window) {
      return;
    }

    const timer = setTimeout(() => {
      if (localStorage && !localStorage.getItem(storageKey)) {
        setHide(false);
      }
    }, 2500);

    return () => {
      clearTimeout(timer);
    }
  }, []);

  if (hide) {
    return null;
  }

  return (
    <div className="overlay">
      <div className="wrapper">
        <div className="banner">
          <div className="flex justifyEnd">
            <span role="button" className="padS noLineH" onClick={() => {
              setHide(true);
              localStorage.setItem(storageKey, true);
            }}>
              <Close />
            </span>
          </div>
          {children}
          <form className="inputSet" method="POST" action="#" onSubmit={handleSubmit}>
            <input ref={inputEl} type="text" name="email" placeholder="email@gmail.com" />
            <button type="submit" aria-label="Subscribe to the newsletter"><span>Subscribe</span></button>
          </form>
          {responseMsg && <p className="response" dangerouslySetInnerHTML={{ __html: responseMsg }} />}
        </div>
      </div>
    </div>
  );
}

export default Overlay;