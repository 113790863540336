import React, { useState, useEffect } from 'react';

import './CookiesTooltip.sass';


const storageKey = 'cookiesOk';

function CookiesTooltip() {
  const [ hide, setHide ] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem(storageKey)) {
      setHide(false);
    }
  }, []);

  if (hide) {
    return null;
  }

  return (
    <div className="tooltip">
      <p>We use cookies to improve your experience. By using our website you are accepting our Cookie Policy.</p>
      <button className="button" onClick={() => {
        setHide(true);
        localStorage.setItem(storageKey, true);
      }} aria-label="Acknowledge cookies notice tooltip">OK</button>
    </div>
  );
}

export default CookiesTooltip;