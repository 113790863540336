import React from 'react';


function Logo() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-707 529 106.9 24">
      <style type="text/css">
        {`.st0{fill:#EABE45;}.st1{fill:#FAFAFA;}`}
      </style>
      <g>
        <path className="st0" d="M-687.2,547.1v1c0,1.1-0.9,2-2,2h-14c-1.1,0-2-0.9-2-2v-14c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2v1h-9
          c-1.1,0-2,0.9-2,2v8c0,1.1,0.9,2,2,2H-687.2z M-696.2,545.1h10v-5.5h-10V545.1z M-692.2,543.8c-0.8,0-1.5-0.7-1.5-1.5
          c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5C-690.7,543.1-691.4,543.8-692.2,543.8z"/>
        <g>
          <path className="st0" d="M-676.9,544h-4.7v2.6c0,0.5,0,0.9,0.1,1c0.1,0.2,0.2,0.2,0.5,0.2c0.3,0,0.5-0.1,0.6-0.3
            c0.1-0.2,0.1-0.6,0.1-1.2v-1.6h3.5v0.9c0,0.7,0,1.3-0.1,1.7c-0.1,0.4-0.3,0.8-0.7,1.3s-0.8,0.8-1.3,1c-0.5,0.2-1.2,0.3-2,0.3
            c-0.8,0-1.4-0.1-2-0.3s-1-0.5-1.4-0.9c-0.3-0.4-0.6-0.8-0.7-1.3c-0.1-0.5-0.2-1.1-0.2-2V542c0-1,0.1-1.9,0.4-2.5s0.7-1.1,1.4-1.4
            c0.6-0.3,1.4-0.5,2.2-0.5c1,0,1.9,0.2,2.5,0.6c0.7,0.4,1.1,0.9,1.4,1.5c0.3,0.6,0.4,1.5,0.4,2.7V544z M-680.6,542.1v-0.9
            c0-0.6,0-1-0.1-1.2c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-0.4,0.1-0.5,0.2s-0.1,0.6-0.1,1.2v0.9H-680.6z"/>
          <path className="st0" d="M-671.9,536.2v1.9h1v1.9h-1v6.4c0,0.8,0,1.2,0.1,1.3c0.1,0.1,0.4,0.1,1,0.1v1.9h-1.5c-0.8,0-1.4,0-1.8-0.1
            c-0.4-0.1-0.7-0.2-0.9-0.5s-0.4-0.5-0.5-0.9c-0.1-0.3-0.1-1.1-0.1-2.3V540h-0.8v-1.9h0.8v-1.9H-671.9z"/>
          <path className="st1" d="M-655.3,544h-4.7v2.6c0,0.5,0,0.9,0.1,1c0.1,0.2,0.2,0.2,0.5,0.2c0.3,0,0.5-0.1,0.6-0.3
            c0.1-0.2,0.1-0.6,0.1-1.2v-1.6h3.5v0.9c0,0.7,0,1.3-0.1,1.7c-0.1,0.4-0.3,0.8-0.7,1.3s-0.8,0.8-1.3,1c-0.5,0.2-1.2,0.3-2,0.3
            c-0.8,0-1.4-0.1-2-0.3c-0.6-0.2-1-0.5-1.4-0.9c-0.3-0.4-0.6-0.8-0.7-1.3c-0.1-0.5-0.2-1.1-0.2-2V542c0-1,0.1-1.9,0.4-2.5
            s0.7-1.1,1.4-1.4c0.6-0.3,1.4-0.5,2.2-0.5c1,0,1.9,0.2,2.5,0.6c0.7,0.4,1.1,0.9,1.4,1.5s0.4,1.5,0.4,2.7V544z M-659,542.1v-0.9
            c0-0.6,0-1-0.1-1.2c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-0.4,0.1-0.5,0.2s-0.1,0.6-0.1,1.2v0.9H-659z"/>
          <path className="st1" d="M-636.4,531.9l-2.1,17.8h-5.8c-0.5-2.7-1-5.9-1.4-9.4c-0.2,1.5-0.6,4.6-1.3,9.4h-5.8l-2.1-17.8h4.5l0.5,6.2
            l0.5,6c0.2-3.1,0.6-7.2,1.3-12.2h4.8c0.1,0.5,0.2,2.5,0.5,5.9l0.5,6.8c0.3-4.3,0.7-8.5,1.3-12.7H-636.4z"/>
          <path className="st1" d="M-632.5,542.5h-3.4v-0.8c0-0.9,0.1-1.6,0.3-2.1c0.2-0.5,0.6-0.9,1.3-1.3c0.6-0.4,1.5-0.6,2.5-0.6
            c1.2,0,2.2,0.2,2.8,0.7c0.6,0.4,1,1,1.1,1.6c0.1,0.6,0.2,1.9,0.2,3.9v6h-3.5v-1.1c-0.2,0.4-0.5,0.7-0.9,1
            c-0.4,0.2-0.8,0.3-1.3,0.3c-0.6,0-1.2-0.2-1.8-0.5c-0.5-0.4-0.8-1.1-0.8-2.3v-1c0-0.9,0.1-1.5,0.4-1.8c0.3-0.3,1-0.7,2.1-1.1
            c1.2-0.5,1.8-0.8,1.9-0.9s0.1-0.5,0.1-1c0-0.6,0-1-0.1-1.2c-0.1-0.2-0.2-0.3-0.5-0.3c-0.2,0-0.4,0.1-0.5,0.2
            c-0.1,0.2-0.1,0.6-0.1,1.2V542.5z M-631.3,544.1c-0.6,0.4-0.9,0.8-1,1.1c-0.1,0.3-0.1,0.7-0.1,1.2c0,0.6,0,1,0.1,1.2
            c0.1,0.2,0.2,0.3,0.5,0.3c0.2,0,0.4-0.1,0.4-0.2c0.1-0.1,0.1-0.5,0.1-1.1V544.1z"/>
          <path className="st1" d="M-622.7,535.2v14.6h-3.8v-14.6H-622.7z"/>
          <path className="st1" d="M-617.6,535.2v14.6h-3.8v-14.6H-617.6z"/>
          <path className="st1" d="M-608.1,544h-4.7v2.6c0,0.5,0,0.9,0.1,1c0.1,0.2,0.2,0.2,0.5,0.2c0.3,0,0.5-0.1,0.6-0.3
            c0.1-0.2,0.1-0.6,0.1-1.2v-1.6h3.5v0.9c0,0.7,0,1.3-0.1,1.7c-0.1,0.4-0.3,0.8-0.7,1.3s-0.8,0.8-1.3,1c-0.5,0.2-1.2,0.3-2,0.3
            c-0.8,0-1.4-0.1-2-0.3s-1-0.5-1.4-0.9c-0.3-0.4-0.6-0.8-0.7-1.3c-0.1-0.5-0.2-1.1-0.2-2V542c0-1,0.1-1.9,0.4-2.5s0.7-1.1,1.4-1.4
            c0.6-0.3,1.4-0.5,2.2-0.5c1,0,1.9,0.2,2.5,0.6c0.7,0.4,1.1,0.9,1.4,1.5c0.3,0.6,0.4,1.5,0.4,2.7V544z M-611.7,542.1v-0.9
            c0-0.6,0-1-0.1-1.2c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-0.4,0.1-0.5,0.2s-0.1,0.6-0.1,1.2v0.9H-611.7z"/>
          <path className="st1" d="M-603,536.2v1.9h1v1.9h-1v6.4c0,0.8,0,1.2,0.1,1.3c0.1,0.1,0.4,0.1,1,0.1v1.9h-1.5c-0.8,0-1.4,0-1.8-0.1
            c-0.4-0.1-0.7-0.2-0.9-0.5s-0.4-0.5-0.5-0.9c-0.1-0.3-0.1-1.1-0.1-2.3V540h-0.8v-1.9h0.8v-1.9H-603z"/>
        </g>
      </g>
    </svg>
  );
}

function AccessTime() {
  return (
    <svg className="contentIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/><path d="M0 0h24v24H0z" fill="none"/><path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"/></svg>
  );
}

function Search() {
  return (
    <svg className="contentIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
  );
}

function Close() {
  return (
    <svg className="contentIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
      <path d="m256 0c-141.49 0-256 114.5-256 256 0 141.49 114.5 256 256 256 141.49 0 256-114.5 256-256 0-141.49-114.5-256-256-256zm-12.284 317.397-58.121 58.132c-6.565 6.553-15.283 10.166-24.557 10.166-19.196 0-34.734-15.526-34.734-34.734 0-9.274 3.612-17.992 10.166-24.557l58.132-58.121c6.785-6.784 6.785-17.783 0-24.568l-58.132-58.121c-6.553-6.565-10.166-15.283-10.166-24.557 0-19.196 15.526-34.734 34.734-34.734 9.274 0 17.992 3.613 24.557 10.166l58.121 58.132c6.785 6.773 17.784 6.773 24.568 0l58.121-58.132c6.565-6.553 15.283-10.166 24.557-10.166 19.196 0 34.734 15.526 34.734 34.734 0 9.274-3.612 17.992-10.166 24.557l-58.132 58.121c-6.785 6.784-6.785 17.783 0 24.568l58.132 58.121c6.553 6.565 10.166 15.283 10.166 24.557 0 19.196-15.526 34.734-34.734 34.734-9.274 0-17.992-3.613-24.557-10.166l-58.121-58.132c-6.784-6.784-17.784-6.773-24.568 0z"/>
    </svg>
  );
}

export {
  Logo,
  AccessTime,
  Search,
  Close
};