import React from 'react';

import 'normalize.css';
import './index.sass';
import Overlay from '../components/Overlay';
import CookiesTooltip from '../components/CookiesTooltip';

function Layout({ children }) {
  return (
    <>
      <Overlay>
        <p className="h1 textCenter noTopM">Stay in the know</p>
        <p className="textCenter noTopM">
          Stay up to date on the latest ewallet, cryptocurrency and online
          payment news. Get <span className="textColorAccent">exclusive bonuses</span>
          by using the greatest <span className="textColorAccent">payment solutions</span>.
          Sign-up for GetEwallet Newsletter today.
        </p>
      </Overlay>
      {children}
      <CookiesTooltip />
    </>
  );
}

export default Layout;